<template>
  <b-card
      class="companysigner-edit-wrapper"
  >
    <!-- form -->
    <b-form id="companysignerForm" class="edit-form mt-2">
      <!--基本信息-->
      <b-card header="基本信息">
        <b-row>
          <!--截止前签约主体-->
          <b-col md="4">
            <modal-select
                label="签约主体（前）*"
                type="input"
                v-on:change="fromChildren($event,['current_sign_company_name','current_sign_company_id'])"
                :params="['current_sign_company_name','current_sign_company_id']"
                modalName="签约主体"
                placeholder="点击选择签约主体"
                v-model="companysigner.current_sign_company_name"
            >
            </modal-select>
          </b-col>
          <!--截至日期-->
          <b-col md="4">
            <b-form-group
                label-cols="3"
                label-cols-lg="3"
                label="截至日期"
                label-for="end_time"
                label-size="sm"
                class="mb-1 required"
            >
              <flat-pickr
                  id="end_time"
                  v-model="end_time"
                  class="form-control"
              />
            </b-form-group>
          </b-col>
          <!--截止后签约主体-->
          <b-col md="4">
            <modal-select
                label="签约主体（后）*"
                type="input"
                v-on:change="fromChildren($event,['new_sign_company_name','new_sign_company_id'])"
                :params="['new_sign_company_name','new_sign_company_id']"
                modalName="签约主体"
                placeholder="点击选择签约主体"
                v-model="companysigner.new_sign_company_name"
            >
            </modal-select>
          </b-col>

          <b-col
              cols="12"
              class="mt-50"
          >
            <b-button
                variant="primary"
                class="mr-1"
                @click="save"
            >
              保存
            </b-button>
            <b-button
                variant="outline-secondary"
                @click="cancel"
            >
              取消
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from "@/store";
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, isEmpty, second} from '@core/utils/filter'
import axios from '@axios'
import ModalSelect from "@/views/components/modal/ModalSelect";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    ModalSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      companysigner: ref({}),
      end_time: null,
    }
  },
  props: {
    company_id: Number,
  },
  setup(props) {
    const toast = useToast()

    const edit = function () {
      axios.post('/api/companysigner/edit', {company_id: props.company_id}).then(res => {
        this.companysigner = res.data.data
        if(!this.companysigner.new){
          this.end_time = toDate(this.companysigner.end_time)
          if(!isEmpty(this.companysigner.ext)){
            this.companysigner.current_sign_company_name = this.companysigner.ext.current_sign_company_name
            this.companysigner.new_sign_company_name = this.companysigner.ext.new_sign_company_name
          }
        }
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      let flag = true
      if (props.company_id == 0) {
        toast.error('请先填写 主体信息')
        flag = false
      }
      if (isEmpty(this.companysigner.current_sign_company_name)) {
        toast.error('请选择 截至前签约主体')
        flag = false
      }
      if (isEmpty(this.end_time)){
        toast.error('请选择 截至日期')
        flag = false
      }
      if (isEmpty(this.companysigner.new_sign_company_name)) {
        toast.error('请选择 截至后签约主体')
        flag = false
      }
      if(!flag) return
      this.companysigner.end_time = second(this.end_time)
      this.companysigner.company_id = props.company_id
      axios.post('/api/companysigner/save', this.companysigner).then(res => {
        toast.success('数据已保存!')
      })
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.companysigner[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    return {
      edit,
      cancel,
      save,
      fromChildren,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
    }
  },
  created() {
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer"},[_c('b-table-simple',{attrs:{"hover":"","small":"","caption-top":""}},[_c('b-thead',{directives:[{name:"show",rawName:"v-show",value:(_vm.items.length > 0),expression:"items.length > 0"}]},[_c('b-tr',[_c('b-th',[_vm._v("序号")]),_c('b-th',[_vm._v("费用科目")]),_c('b-th',[_vm._v("扣费类型")]),_c('b-th',[_vm._v("金额|百分比")]),_c('b-th',[_vm._v("品牌必选")]),_c('b-th',[_vm._v("品牌")]),_c('b-th',[_vm._v("操作")])],1)],1),_c('b-tbody',_vm._l((_vm.items),function(item,index){return _c('b-tr',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-td',[_c('span',[_vm._v(_vm._s(index + 1))])]),_c('b-td',[_c('ValidationProvider',{attrs:{"rules":"required","name":"费用科目","vid":("cost_type_" + index)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"options":_vm.getCodeOptions('sales_cost_type'),"placeholder":"请选择费用科目"},on:{"input":function($event){return _vm.changeSelect(item,$event)}},model:{value:(item.cost_type),callback:function ($$v) {_vm.$set(item, "cost_type", $$v)},expression:"item.cost_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-td',[_c('ValidationProvider',{attrs:{"rules":"required","name":"扣费类型","vid":("charge_type_" + index)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"options":_vm.getCodeOptions('sales_cost_charge_type'),"placeholder":"请选择扣费类型"},model:{value:(item.charge_type),callback:function ($$v) {_vm.$set(item, "charge_type", $$v)},expression:"item.charge_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-td',[_c('ValidationProvider',{attrs:{"rules":"required","name":"金额|百分比","vid":("rule_amount_" + index)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.isNumber(_vm.isEmpty(item.charge_type)?0:item.charge_type.value)===3)?_c('div',[_c('b-form-input',{attrs:{"id":"rule_amount_per","type":"number","state":errors.length > 0 ? false:null},on:{"change":function($event){return _vm.checkData(item)}},model:{value:(item.rule_amount),callback:function ($$v) {_vm.$set(item, "rule_amount", $$v)},expression:"item.rule_amount"}})],1):_c('div',[_c('b-form-input',{attrs:{"id":"rule_amount","type":"number","state":errors.length > 0 ? false:null},model:{value:(item.rule_amount),callback:function ($$v) {_vm.$set(item, "rule_amount", $$v)},expression:"item.rule_amount"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-td',[(_vm.isInArr([3,5,6,7,8],_vm.isNumber(_vm.isEmpty(item.cost_type)?0:item.cost_type.value)))?_c('div',[_c('label',[_vm._v("是")])]):_c('div',[_c('label',[_vm._v("否")])])]),_c('b-td',[(_vm.isInArr([3,5,6,7,8],_vm.isNumber(_vm.isEmpty(item.cost_type)?0:item.cost_type.value)))?_c('div',[_c('ValidationProvider',{attrs:{"rules":"required","name":"品牌","vid":("brand_id_" + index)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"brand_id","options":_vm.getCodeOptions('brand'),"clearable":true,"placeholder":"请选择品牌"},on:{"input":function($event){return _vm.changeSelectBrand(item,$event)}},model:{value:(item.brand_id),callback:function ($$v) {_vm.$set(item, "brand_id", $$v)},expression:"item.brand_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_c('div',[_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"brand_id_s","options":_vm.getCodeOptions('brand'),"clearable":true,"placeholder":"请选择品牌"},on:{"input":function($event){return _vm.changeSelectBrand(item,$event)}},model:{value:(item.brand_id),callback:function ($$v) {_vm.$set(item, "brand_id", $$v)},expression:"item.brand_id"}})],1)]),_c('b-td',[_c('b-button',{attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("删除")])],1)],1)],1)}),1),_c('b-tfoot')],1)],1),_c('b-col',{staticClass:"mt-50",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"mr-1",attrs:{"variant":"primary"},on:{"click":_vm.addAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("增加一行")])],1),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.save}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"SaveIcon"}}),_c('span',[_vm._v("保存")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <b-tabs>
      <b-tab title="主体信息">
        <offline-customer-entity :company_id="company_id" v-on:push_company_id="getCompanyId($event)"></offline-customer-entity>
      </b-tab>

      <b-tab title="联系人信息">
        <offline-customer-contact :company_id="company_id"></offline-customer-contact>
      </b-tab>

      <b-tab title="银行账户">
        <offline-customer-bak-acc :company_id="company_id"></offline-customer-bak-acc>
      </b-tab>

      <b-tab title="账期信息">
        <offline-customer-warehouse-statement :company_id="company_id"></offline-customer-warehouse-statement>
      </b-tab>
      <b-tab title="证照附件">
        <offline-customer-attachments :company_id="company_id"></offline-customer-attachments>
      </b-tab>

      <b-tab title="签约主体">
        <offline-customer-signer :company_id="company_id"></offline-customer-signer>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTabs, BTab,
} from 'bootstrap-vue'
import offlineCustomerAttachments from "@/views/apps/company-new/offlineCustomer/OfflineCustomerAttachments";
import offlineCustomerBakAcc from "@/views/apps/company-new/offlineCustomer/OfflineCustomerBakAcc";
import offlineCustomerContact from "@/views/apps/company-new/offlineCustomer/OfflineCustomerContact";
import offlineCustomerEntity from "@/views/apps/company-new/offlineCustomer/OfflineCustomerEntity";
import offlineCustomerWarehouseStatement from "@/views/apps/company-new/offlineCustomer/OfflineCustomerWarehouseStatement";
import offlineCustomerSigner from "@/views/apps/company-new/offlineCustomer/OfflineCustomerSigner";
import {getCode, getCodeColor, getCodeLabel, getCodeOptions, isEmpty} from "@core/utils/filter";
import {ref} from "@vue/composition-api";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTabs,
    BTab,
    offlineCustomerAttachments,
    offlineCustomerBakAcc,
    offlineCustomerContact,
    offlineCustomerEntity,
    offlineCustomerWarehouseStatement,
    offlineCustomerSigner,
  },
  data() {
    return {
      company_id:ref(0),
    }
  },
  setup() {
    const getCompanyId = function (param){
      this.company_id = param
    }

    return {
      getCompanyId,
    }
  },
  created() {
    this.company_id = Number.parseInt(this.$route.query.id) || 0
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<template>
  <b-card>
    <b-form id="offlineCustomerEntityForm" class="edit-form mt-2">

      <!--基本信息-->
      <b-col md="12">
        <b-card header="基本信息">
          <b-row>
            <!--公司全称-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="公司全称"
                  label-for="company_name"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="company_name"
                    size="sm"
                    :readonly="afterAudit==1"
                    v-model="offlineCustomer.company_name"
                />
              </b-form-group>
            </b-col>
            <!--公司简称-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="公司简称"
                  label-for="nick_name"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="nick_name"
                    size="sm"
                    v-model="offlineCustomer.nick_name"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="映射客户名称"
                  label-for="mapping_name"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="mapping_name"
                    size="sm"
                    v-model="offlineCustomer.mapping_name"
                />
              </b-form-group>
            </b-col>
            <!--公司id-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="公司ID"
                  label-for="one_company_id"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="one_company_id"
                    size="sm"
                    v-model="offlineCustomer.one_company_id"
                    readonly
                />
              </b-form-group>
            </b-col>
            <!--统一社会信用代码-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="统一社会信用代码"
                  label-for="uniform_social_credit_code"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="uniform_social_credit_code"
                    size="sm"
                    :readonly="afterAudit==1"
                    v-model="offlineCustomer.uniform_social_credit_code"
                />
              </b-form-group>
            </b-col>
            <!--纳税类型-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="纳税类型"
                  label-for="pay_taxes"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="pay_taxes"
                    class="select-size-sm"
                    :options="getCodeOptions('company_pay_taxes')"
                    @input="changeSelect('pay_taxes',$event)"
                    v-model="pay_taxes"
                    placeholder="请选择纳税类型"
                />
              </b-form-group>
            </b-col>
            <!--企业性质-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="企业性质"
                  label-for="company_properties"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="company_properties"
                    class="select-size-sm"
                    :options="getCodeOptions('company_properties')"
                    @input="changeSelect('company_properties',$event)"
                    v-model="company_properties"
                    placeholder="请选择企业性质"
                />
              </b-form-group>
            </b-col>
            <!--注册地-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="注册地"
                  label-for="registration"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="registration"
                    class="select-size-sm"
                    :options="getCodeOptions('company_registration')"
                    v-model="registration"
                    @input="changeSelect('registration',$event)"
                    placeholder="请选择注册地"
                />
              </b-form-group>
            </b-col>
            <!--注册日期-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="注册日期"
                  label-for="registration_date"
                  label-size="sm"
                  class="mb-1"
              >
                <flat-pickr
                    id="registration_date"
                    v-model="registration_date"
                    class="form-control"
                />
              </b-form-group>
            </b-col>
            <!--公司类别-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="客户类型"
                  label-for="company_kind"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="company_kind"
                    :options="getCodeOptions('company_kind_ZDKH')"
                    :clearable="true"
                    v-model="companyKind"
                    @input="changeSelect('companyKind',$event)"
                    class="select-size-sm"
                    placeholder="请选择公司类别"
                />
              </b-form-group>
            </b-col>
            <!--国家-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="国家"
                  label-for="country"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="country"
                    size="sm"
                    readonly
                    value="中国"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <!--省份-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="省份"
                  label-for="province_id"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="province_id"
                    :options="province_options"
                    v-model="province"
                    @input="changeProvince($event)"
                    class="select-size-sm"
                    placeholder="请选择省份"
                />
              </b-form-group>
            </b-col>
            <!--城市-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="城市"
                  label-for="city_id"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="city_id"
                    :options="city_options"
                    :disabled="city_disable"
                    v-model="city"
                    @input="changeCity($event)"
                    class="select-size-sm"
                    placeholder="请选择城市"
                />
              </b-form-group>
            </b-col>
            <!--地区-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="地区"
                  label-for="area_id"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="area_id"
                    :options="area_options"
                    :disabled="area_disable"
                    v-model="area"
                    class="select-size-sm"
                    placeholder="请选择地区"
                />
              </b-form-group>
            </b-col>
            <!--详细地址-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="详细地址"
                  label-for="address"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="address"
                    size="sm"
                    v-model="offlineCustomer.address"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!--法人信息-->
      <b-col md="12">
        <b-card header="法人信息">
          <b-row>
            <!--法人姓名-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="法人姓名"
                  label-for="actual_controller"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="actual_controller"
                    size="sm"
                    v-model="offlineCustomer.actual_controller"
                />
              </b-form-group>
            </b-col>
            <!--法人证件号-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="法人证件号"
                  label-for="actual_card"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="actual_card"
                    size="sm"
                    v-model="offlineCustomer.actual_card"
                />
              </b-form-group>
            </b-col>
            <!--法人手机号-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="法人手机号"
                  label-for="actual_phone"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="actual_phone"
                    size="sm"
                    v-model="offlineCustomer.actual_phone"
                />
              </b-form-group>
            </b-col>
            <!--法人邮箱-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="法人邮箱"
                  label-for="legal_email"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="legal_email"
                    size="sm"
                    v-model="offlineCustomer.legal_email"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!--开票信息-->
      <b-col md="12">
        <b-card header="开票信息">
          <b-row>
            <!--是否开票-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="是否开票"
                  label-for="is_invoice"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="is_invoice"
                    class="select-size-sm"
                    :options="getCodeOptions('yesno')"
                    v-model="is_invoice"
                    @input="changeSelect('is_invoice',$event)"
                    placeholder="请选择是否开票"
                />
              </b-form-group>
            </b-col>
            <!--公司名称-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="公司名称"
                  label-for="invoice_head"
                  label-size="sm"
                  :class="`mb-1 ${this.offlineCustomer.is_invoice==0 ? '' : 'required'}`"
              >
                <b-form-input
                    id="invoice_head"
                    size="sm"
                    v-model="offlineCustomer.invoice_head"
                />
              </b-form-group>
            </b-col>
            <!--纳税人识别号-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="纳税人识别号"
                  label-for="invoice_tax"
                  label-size="sm"
                  :class="`mb-1 ${this.offlineCustomer.is_invoice==0 ? '' : 'required'}`"
              >
                <b-form-input
                    id="invoice_tax"
                    size="sm"
                    v-model="offlineCustomer.invoice_tax"
                />
              </b-form-group>
            </b-col>
            <!--公司地址-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="公司地址"
                  label-for="invoice_link_address"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="invoice_link_address"
                    size="sm"
                    v-model="offlineCustomer.invoice_link_address"
                    placeholder="专用发票请必填"
                />
              </b-form-group>
            </b-col>
            <!--公司电话-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="公司电话"
                  label-for="invoice_link_tel"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="invoice_link_tel"
                    size="sm"
                    v-model="offlineCustomer.invoice_link_tel"
                    placeholder="专用发票请必填"
                />
              </b-form-group>
            </b-col>
            <!--开户行-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="开户行"
                  label-for="invoice_bank_name"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="invoice_bank_name"
                    size="sm"
                    v-model="offlineCustomer.invoice_bank_name"
                    placeholder="专用发票请必填"
                />
              </b-form-group>
            </b-col>
            <!--开户账号-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="开户账号"
                  label-for="invoice_bank_no"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="invoice_bank_no"
                    size="sm"
                    v-model="offlineCustomer.invoice_bank_no"
                    placeholder="专用发票请必填"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!--归属信息-->
      <b-col md="12">
        <b-card header="归属信息">
          <b-row>
            <!--负责人-->
            <b-col md="4">
              <modal-select
                  label="负责人 *"
                  type="input"
                  v-on:change="fromChildren($event,['user_name','user_id'])"
                  :params="['user_name','user_id']"
                  modalName="责任人"
                  placeholder="点击选择责任人"
                  v-model="offlineCustomer.user_name"
              >
              </modal-select>
            </b-col>

            <!--店铺名称-->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="店铺名称"
                  label-for="store_name"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="store_name"
                    size="sm"
                    v-model="offlineCustomer.store_name"
                    v-b-modal.modal-select-store
                    placeholder="点击搜索店铺名称"
                />
              </b-form-group>
            </b-col>
            <!--店铺名称 modal-->
            <b-modal
                id="modal-select-store"
                ok-only
                ok-title="确认"
                @ok="onSelectStore"
                cancel-title="取消"
                centered
                size="lg"
                title="选择店铺"
            >
              <store-list ref="storeList" :store_id="offlineCustomer.store_id"
                          :store_name="offlineCustomer.store_name"/>
            </b-modal>

            <!--授信额度-->
            <b-col md="4" v-if="showCredit">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="授信额度"
                  label-for="limit_amount1"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="limit_amount1"
                    size="sm"
                    v-model="offlineCustomer.limit_amount"
                    v-b-modal.modal-company-credit
                    placeholder="点击申请授信"
                />
              </b-form-group>
            </b-col>

          </b-row>
        </b-card>
      </b-col>

      <!--签约信息-->
      <b-col md="12">
        <b-card header="签约信息">
          <b-row>
            <!--签约主体-->
            <b-col md="4">
              <modal-select
                  label="签约主体"
                  type="input"
                  v-on:change="fromChildren($event,['sign_company_name','sign_company_id'])"
                  :params="['sign_company_name','sign_company_id']"
                  modalName="签约主体"
                  placeholder="点击选择签约主体"
                  v-model="offlineCustomer.sign_company_name"
              >
              </modal-select>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col
          cols="12"
          class="mt-50"
      >
        <b-button
            variant="primary"
            class="mr-1"
            @click="save"
        >
          保存
        </b-button>
        <b-button
            variant="outline-secondary"
            class="mr-1"
            @click="cancel"
        >
          返回
        </b-button>
        <b-button
            variant="success"
            @click="save(1)"
            v-if="user.role_id === 14 || user.role_id === 29 || user.role_id === 31"
        >
          审核通过
        </b-button>
      </b-col>

    </b-form>
    <!--申请授信 modal-->
    <b-modal
        id="modal-company-credit"
        ok-title="确认"
        cancel-title="取消"
        @ok="saveCredit"
        centered
        size="lg"
        title="申请授信"
    >
      <b-row>
        <!--公司名称（readOnly）-->
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="公司名称"
              label-for="credit_company_name"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="credit_company_name"
                size="sm"
                readonly
                :value="offlineCustomer.company_name"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <!--店铺名称-->
        <b-col md="6">
          <modal-select
              label="店铺名称 *"
              type="input"
              v-on:change="fromChildren($event,['credit_store_name','credit_store_id'])"
              :params="['credit_store_name','credit_store_id']"
              :company_id="90"
              :store_ids="offlineCustomer.store_id"
              modalName="店铺"
              placeholder="点击选择店铺"
              v-model="offlineCustomer.credit_store_name"
          >
          </modal-select>
        </b-col>
        <!--授信日期-->
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="授信日期"
              label-for="credit_date"
              label-size="sm"
              class="mb-1 required"
          >
            <flat-pickr
                id="credit_date"
                v-model="offlineCustomer.credit_date"
                class="form-control"
            />
          </b-form-group>
        </b-col>
        <!--授信额度-->
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="授信额度"
              label-for="limit_amount"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="limit_amount"
                size="sm"
                type="number"
                @blur="fixNumber('limit_amount')"
                v-model="offlineCustomer.limit_amount"
            />
          </b-form-group>
        </b-col>
        <!--授信有效期-->
      </b-row>
      <!--申请备注-->
      <b-row>
        <b-col md="6">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="备注"
              label-for="memo"
              label-size="sm"
              class="mb-1"
          >
            <b-form-textarea
                id="memo"
                size="sm"
                v-model="offlineCustomer.memo"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>

</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from "@/store";
import {useToast} from 'vue-toastification/composition'
import axios from '@axios'
import ModalSelect from "@/views/components/modal/ModalSelect";
import {
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  addNumber,
  isEmpty, second
} from '@core/utils/filter'
import companyStore from "@/views/apps/company/companyStore";
import StoreList from "@/views/apps/store/store-modal/StoreListMutiSelect";
import CompanyCreditEdit from "@/views/apps/companycredit/CompanyCreditEdit";
import {getUserData} from "@/auth/utils";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    addNumber,
    isEmpty,
    Number,
    ModalSelect,
    StoreList,
    CompanyCreditEdit,
  },
  data() {
    return {
      id: ref(0),
      offlineCustomer: ref({}),
      city_disable: true,
      area_disable: true,
      province_options: [],
      city_options: [],
      area_options: [],
      province: null,
      city: null,
      area: null,
      pay_taxes: null,
      company_properties: null,
      registration: null,
      is_invoice: null,
      registration_date: null,
      afterAudit: '',
      companyKind: null,
      showCredit: true,
      user: {},
    }
  },
  props: {
    company_id: Number,
  },
  setup(props) {
    const toast = useToast()

    // Register module
    if (!store.hasModule('companystore')) store.registerModule('companystore', companyStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('companystore')) store.unregisterModule('companystore')
    })

    const edit = function () {
      this.getProvinceOptions()

      store.dispatch('companystore/edit', {id: props.company_id}).then(res => {
        this.offlineCustomer = res.data.data
        this.registration = getCode('company_registration', this.offlineCustomer.registration)
        this.is_invoice = getCode('yesno', this.offlineCustomer.is_invoice)
        this.offlineCustomer.user_name = getCodeLabel('user', this.offlineCustomer.user_id)
        this.offlineCustomer.store_id = this.offlineCustomer.ext.store_id
        this.offlineCustomer.store_name = this.offlineCustomer.ext.store_name
        if (!this.offlineCustomer.new) {
          //编辑
          this.registration_date = toDate(this.offlineCustomer.registration_date)
          this.pay_taxes = getCode('company_pay_taxes', this.offlineCustomer.pay_taxes)
          this.company_properties = getCode('company_properties', this.offlineCustomer.company_properties)
          this.province = {label: this.offlineCustomer.ext.province_name, value: this.offlineCustomer.province_id}
          this.city = {label: this.offlineCustomer.ext.city_name, value: this.offlineCustomer.city_id}
          this.getCityOptions(this.offlineCustomer.province_id)
          this.area = {label: this.offlineCustomer.ext.area_name, value: this.offlineCustomer.area_id}
          this.getAreaOptions(this.offlineCustomer.city_id)
          this.city_disable = false
          this.area_disable = false
          this.companyKind = getCode('company_kind_ZDKH', this.offlineCustomer.company_kind)
          this.showCredit = false
        }
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function (directAuditFlag) {
      let flag = true
      if (isEmpty(this.offlineCustomer.company_name)) {
        toast.error('请填写 公司全称')
        flag = false
      }
      if (isEmpty(this.offlineCustomer.nick_name)) {
        toast.error('请填写 公司简称')
        flag = false
      }
      if (isEmpty(this.offlineCustomer.uniform_social_credit_code)) {
        toast.error('请填写 统一社会信用代码')
        flag = false
      }
      if (isEmpty(this.offlineCustomer.pay_taxes)) {
        toast.error('请选择 纳税类型')
        flag = false
      }
      if (isEmpty(this.offlineCustomer.company_properties)) {
        toast.error('请选择 企业性质')
        flag = false
      }
      if (isEmpty(this.offlineCustomer.registration)) {
        toast.error('请选择 注册地')
        flag = false
      }
      if (isEmpty(this.companyKind)) {
        toast.error('请选择 客户类型')
        flag = false
      }
      if (isEmpty(this.province)) {
        toast.error('请选择 省份')
        flag = false
      }
      if (isEmpty(this.city)) {
        toast.error('请选择 城市')
        flag = false
      }
      if (isEmpty(this.area)) {
        toast.error('请选择 地区')
        flag = false
      }
      if (isEmpty(this.offlineCustomer.actual_controller)) {
        toast.error('请填写 法人姓名')
        flag = false
      }
      if (this.offlineCustomer.is_invoice == null) {
        toast.error('请选择 是否开票')
        flag = false
      }
      if (this.offlineCustomer.is_invoice == 1) {
        if (isEmpty(this.offlineCustomer.invoice_head)) {
          toast.error('请填写 开票公司名称')
          flag = false
        }
        if (isEmpty(this.offlineCustomer.invoice_tax)) {
          toast.error('请填写 纳税人识别号')
          flag = false
        }
      }
      if(this.showCredit &&isEmpty(this.offlineCustomer.limit_amount)){
        toast.error('请填写 授信额度')
        flag = false
      }
      if (!flag) return

      this.offlineCustomer.company_type = 6
      this.offlineCustomer.province_id = this.province.value
      this.offlineCustomer.city_id = this.city.value
      this.offlineCustomer.area_id = this.area.value
      this.offlineCustomer.registration_date = second(this.registration_date)
      this.offlineCustomer.companyKind = this.companyKind.value
      if (!isEmpty(this.offlineCustomer.offline_customer_credit)) {
        this.offlineCustomer.offline_customer_credit.credit_date = second(this.offlineCustomer.offline_customer_credit.credit_date)
      }
      this.offlineCustomer.directAuditFlag = directAuditFlag === 1 ? 1 : 0
      axios.post('api/company/save', this.offlineCustomer).then(res => {
        if (res.data.code == 0) {
          toast.success('数据已保存,可进行其他操作!')
          this.offlineCustomer.company_id = res.data.data.company_id
          this.$emit('push_company_id', this.offlineCustomer.company_id)
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const changeProvince = function (param) {
      if (!isEmpty(param)) {
        this.city_disable = false
        this.city_options = []
        this.getCityOptions(param.value)
      } else {
        this.city_disable = true
        this.area_disable = true
      }
      this.city = null
      this.area = null
      this.$forceUpdate()
    }

    const changeCity = function (param) {
      if (!isEmpty(param)) {
        this.area_disable = false
        this.area_options = []
        this.getAreaOptions(param.value)
      } else {
        this.area_disable = true
      }
      this.area = null
      this.$forceUpdate()
    }

    const getProvinceOptions = function () {
      axios.post('api/area/getProvince').then(res => {
        let area = res.data.data
        this.province_options = []
        for (let i = 0; i < area.length; i++) {
          this.province_options.push({label: area[i].area_name, value: area[i].area_id})
        }
      })
    }

    const getCityOptions = function (parent_id) {
      axios.post('api/area/getCity', {parentId: parent_id}).then(res => {
        let area = res.data.data
        this.city_options = []
        for (let i = 0; i < area.length; i++) {
          this.city_options.push({label: area[i].area_name, value: area[i].area_id})
        }
      })
    }

    const getAreaOptions = function (parent_id) {
      axios.post('api/area/getArea', {parentId: parent_id}).then(res => {
        let area = res.data.data
        this.area_options = []
        for (let i = 0; i < area.length; i++) {
          this.area_options.push({label: area[i].area_name, value: area[i].area_id})
        }
      })
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.offlineCustomer[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const changeSelect = function (key, param) {
      if (!isEmpty(param)) {
        this.offlineCustomer[key] = param.value
      } else {
        this.offlineCustomer[key] = null
      }
      this.$forceUpdate()
    }

    const onSelectStore = function () {
      let store_id_arr = []
      let store_name_arr = []
      let select = this.$refs.storeList.getSelected()
      for (let i = 0; i < select.length; i++) {
        store_id_arr.push(select[i].store_id)
        store_name_arr.push(select[i].store_name)
      }
      this.offlineCustomer.store_name = store_name_arr.toString()
      this.offlineCustomer.store_id = store_id_arr.toString()
      this.$forceUpdate()
    }

    const saveCredit = function (bvModalEvt) {
      let flag = true
      if (isEmpty(this.offlineCustomer.credit_store_name)) {
        toast.error("请选择 店铺")
        bvModalEvt.preventDefault()
        flag = false
      }
      if (isEmpty(this.offlineCustomer.credit_date)) {
        toast.error("请选择 授信日期")
        bvModalEvt.preventDefault()
        flag = false
      }
      if (isEmpty(this.offlineCustomer.limit_amount)) {
        toast.error("请填写 授信额度")
        bvModalEvt.preventDefault()
        flag = false
      }
      if (this.offlineCustomer.limit_amount < 0) {
        toast.error("授信额度 不能为负")
        bvModalEvt.preventDefault()
        flag = false
      }
      if (!flag) return
      //组装结构体
      let offlineCustomerCredit = {}
      offlineCustomerCredit.store_name = this.offlineCustomer.credit_store_name
      offlineCustomerCredit.store_id = this.offlineCustomer.credit_store_id
      offlineCustomerCredit.credit_date = this.offlineCustomer.credit_date
      offlineCustomerCredit.limit_amount = this.offlineCustomer.limit_amount
      offlineCustomerCredit.memo = this.offlineCustomer.memo
      this.offlineCustomer.offline_customer_credit = offlineCustomerCredit
      //同步店铺名称
      if(isEmpty(this.offlineCustomer.store_name)){
        this.offlineCustomer.store_name = this.offlineCustomer.credit_store_name.toString()
      }
      if(isEmpty(this.offlineCustomer.store_id)){
        this.offlineCustomer.store_id = this.offlineCustomer.credit_store_id.toString()
      }
      this.$forceUpdate()
    }

    const fixNumber = function (type) {
      if (!isEmpty(this.offlineCustomer[type])) {
        this.offlineCustomer[type] = Number(this.offlineCustomer[type]).toFixed(2)
      } else {
        this.offlineCustomer[type] = ''
      }
      this.$forceUpdate()
    }

    return {
      edit,
      cancel,
      save,
      getProvinceOptions,
      getCityOptions,
      getAreaOptions,
      changeProvince,
      changeCity,
      fromChildren,
      changeSelect,
      onSelectStore,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      Number,
      saveCredit,
      fixNumber,
    }
  },
  created() {
    this.afterAudit = this.$route.query.afterAudit || 0;  //是否为审核后再修改
    this.edit()
    const userData = getUserData()
    this.user = userData
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

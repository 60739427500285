<template>

  <div>
    <ValidationObserver ref="observer">
      <b-table-simple hover small caption-top >
        <b-thead v-show="items.length > 0">
          <b-tr>
            <b-th>序号</b-th>
            <b-th>费用科目</b-th>
            <b-th>扣费类型</b-th>
            <b-th>金额|百分比</b-th>
            <b-th>品牌必选</b-th>
            <b-th>品牌</b-th>
            <b-th>操作</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, index) in items"
                :id="item.id"
                :key="item.id"
                ref="row"
          >
            <b-td>
              <span>{{ index + 1 }}</span>
            </b-td>
            <b-td>
              <ValidationProvider rules="required" name="费用科目" #default="{ errors }" :vid="`cost_type_${index}`">
                <v-select
                        :options="getCodeOptions('sales_cost_type')"
                        v-model="item.cost_type"
                        class="select-size-sm"
                        placeholder="请选择费用科目"
                        @input="changeSelect(item,$event)"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-td>
            <b-td>
              <ValidationProvider rules="required" name="扣费类型" #default="{ errors }" :vid="`charge_type_${index}`">
                <v-select
                        :options="getCodeOptions('sales_cost_charge_type')"
                        v-model="item.charge_type"
                        class="select-size-sm"
                        placeholder="请选择扣费类型"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>

            </b-td>
            <b-td>
              <ValidationProvider rules="required" name="金额|百分比" #default="{ errors }" :vid="`rule_amount_${index}`">
                <!--按销售额比例-->
                <div v-if="isNumber(isEmpty(item.charge_type)?0:item.charge_type.value)===3">
                   <b-form-input
                           id="rule_amount_per"
                           type="number"
                           :state="errors.length > 0 ? false:null"
                           v-model="item.rule_amount"
                           @change="checkData(item)"
                   />
                </div>
                <div v-else>
                  <b-form-input
                          id="rule_amount"
                          type="number"
                          :state="errors.length > 0 ? false:null"
                          v-model="item.rule_amount"
                  />
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </b-td>
            <b-td>
              <div v-if="isInArr([3,5,6,7,8],isNumber(isEmpty(item.cost_type)?0:item.cost_type.value))">
                <label>是</label>
              </div>
              <div v-else>
                <label>否</label>
              </div>
            </b-td>
            <b-td>

              <div v-if="isInArr([3,5,6,7,8],isNumber(isEmpty(item.cost_type)?0:item.cost_type.value))">
                <ValidationProvider rules="required" name="品牌" #default="{ errors }"
                                    :vid="`brand_id_${index}`"
                >
                  <v-select
                          id="brand_id"
                          :options="getCodeOptions('brand')"
                          :clearable="true"
                          v-model="item.brand_id"
                          class="select-size-sm"
                          placeholder="请选择品牌"
                          @input="changeSelectBrand(item,$event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </div>

              <div v-else>
                <v-select
                        id="brand_id_s"
                        :options="getCodeOptions('brand')"
                        :clearable="true"
                        v-model="item.brand_id"
                        class="select-size-sm"
                        placeholder="请选择品牌"
                        @input="changeSelectBrand(item,$event)"
                />
              </div>
            </b-td>
            <b-td>
              <b-button
                      variant="outline-danger"
                      @click="removeItem(index)"
              >
                <feather-icon
                        icon="XIcon"
                        class="mr-25"
                />
                <span>删除</span>
              </b-button>
            </b-td>

          </b-tr>
        </b-tbody>
        <b-tfoot>
        </b-tfoot>
      </b-table-simple>
    </ValidationObserver>
    <b-col cols="12"
           class="mt-50"
    >
      <b-button
              variant="primary"
              class="mr-1"
              @click="addAgain"

      >
        <feather-icon
                icon="PlusIcon"
                class="mr-25"
        />
        <span>增加一行</span>
      </b-button>
      <b-button
              variant="primary"
              @click="save"
      >
        <feather-icon
                icon="SaveIcon"
                class="mr-25"
        />
        <span>保存</span>
      </b-button>
    </b-col>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isNumber,
  isEmpty,
  isEmptyNew,
  isInArr,removeDuplicate
} from '@core/utils/filter'
import warehousestatementruleStore from '@/views/apps/warehousestatementrule/warehousestatementruleStore'
import {useToast} from "vue-toastification/composition";
import brandStore from "@/views/apps/brand/brandStore";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  methods: {
    addAgain() {
      this.items.push({ state: 0 })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
  },
  props:{
    companyId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      items: [],
      saveShow: true,
      //brandArray: [],
    }
  },
  setup(props) {
    // Register module
    if (!store.hasModule('warehousestatementrule')) store.registerModule('warehousestatementrule', warehousestatementruleStore)
    if (!store.hasModule('brand')) store.registerModule('brand', brandStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('warehousestatementrule')) store.unregisterModule('warehousestatementrule')
      if (store.hasModule('brand')) store.unregisterModule('brand')
    })

    const toast = useToast()


    const edit = function () {
      store.dispatch('warehousestatementrule/search', {company_id:props.companyId}).then(res => {
        let ruleList = res.data.data.list
        if (!isEmpty(ruleList)) {
          for(let i=0;i<ruleList.length;i++){
              let item = []
              item.cost_type = getCode('sales_cost_type', ruleList[i].cost_type)
              item.charge_type = getCode('sales_cost_charge_type', ruleList[i].charge_type)
              item.rule_amount = ruleList[i].rule_amount
              item.brand_id = getCode('brand', ruleList[i].brand_id)
              item.rule_id = ruleList[i].rule_id
              this.items.push(item)
          }
        }
      })
    }
    const save = async function () {
      if (this.items.length !== 0) {
        let items = this.items
        let success = await this.$refs.observer.validate()
        if (success) {
          let rule_id = []
          let cost_type = []
          let charge_type = []
          let rule_amount = []
          let brand_id = []
          items.forEach(item => {
            rule_id.push(isEmpty(item['rule_id'])?0:item['rule_id'])
            cost_type.push(isEmpty(item['cost_type'])?0:item['cost_type'].value)
            charge_type.push(isEmpty(item['charge_type'])?0:item['charge_type'].value)
            rule_amount.push(isEmpty(item['rule_amount'])?0:item['rule_amount'])
            brand_id.push(isEmpty(item['brand_id'])?0:item['brand_id'].value)
          })
          let newArr = removeDuplicate(cost_type)
          if(newArr.length!==cost_type.length){
            toast.error("同一个客户一个费用科目只能添加一条记录,请重新选择!")
            return false;
          }
          //this.saveShow = false
          store.dispatch('warehousestatementrule/saveAll', {
            company_id: props.companyId,
            ruleIds:rule_id.join(","),
            costType: cost_type.join(","),
            chargeType: charge_type.join(","),
            ruleAmount: rule_amount.join(","),
            brandId: brand_id.join(",")
          }).then(res => {
                    if (res.data.code === 0) {
                      toast.success('数据已保存!')
                      this.$emit('refresh', 'refresh')
                    } else {
                      toast.error(res.data.data)
                    }
                  })
        } else {
          toast.error('请填写缺失字段')
        }
      } else {
        store.dispatch('warehousestatementrule/deleteAll', {
          company_id: props.companyId,
        }).then(res => {
          if (res.data.code === 0) {
            toast.success('数据已保存!')
            this.$emit('refresh', 'refresh')
          } else {
            toast.error(res.data.data)
          }
        })
      }
    }

    const getBrand = function (d) {
      store.dispatch('brand/searchNormal').then(res => {
        let a = res.data.data.list
        for (let i = 0; i < a.length; i++) {
          d.push({"label": a[i].name, "brand_id": a[i].brand_id, "is_white": a[i].is_white})
        }
      })
    }

    const changeSelect = function (item, event) {
      item.cost_type = isEmpty(event) ? null : event
      this.$forceUpdate()
    }

    const changeSelectBrand = function (item, event) {
      item.brand_id = isEmpty(event) ? null : event
      this.$forceUpdate()
    }

    const checkData = function (item) {
      let ruleAmount = item.rule_amount
      if(isNumber(ruleAmount)>1||isNumber(ruleAmount)<0){
        item.rule_amount = 0;
        toast.error("百分比请填入0-1之间的小数,例如：12%填0.12!")
        return
      }
    }

    return {
      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
     toTime,
     toDate,
      isNumber,edit,save,getBrand,isEmptyNew,isInArr,isEmpty,removeDuplicate,changeSelect,changeSelectBrand,checkData
    }
  },
  created() {
    //this.getBrand(this.brandArray)
    this.edit()
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

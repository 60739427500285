<template>
  <b-card>
    <b-form id="customerBakAccForm" class="edit-form mt-2">

      <!--基本信息-->
      <b-col md="12">
        <b-card header="基本信息">
          <b-row>
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="对账方式"
                  label-for="statement_type"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="statement_type"
                    :options="getCodeOptions('statement_type')"
                    @input="changeSelect('statement_type',$event)"
                    :clearable="true"
                    v-model="statement_type"
                    class="select-size-sm"
                    placeholder="请选择对账方式"
                    disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="对账日期"
                  label-for="statement_day"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="registration_date"
                    size="sm"
                    type="number"
                    v-model="warehousestatement.statement_day"
                    placeholder="请填写1-28之间的数字，对应每个月的几号"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="到账周期"
                  label-for="statement_over"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="statement_over"
                    size="sm"
                    type="number"
                    v-model="warehousestatement.statement_over"
                    placeholder="天"
                    readonly
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="付款周期"
                  label-for="pay_period"
                  label-size="sm"
                  class="mb-1 required"
              >
                <v-select
                    id="pay_period"
                    :options="getCodeOptions('preorder_pay_preiod')"
                    @input="changeSelect('pay_period',$event)"
                    :clearable="true"
                    v-model="pay_period"
                    class="select-size-sm"
                    placeholder="请选择付款周期"
                />
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="备注"
                  label-for="memo"
                  label-size="sm"
                  :class="`mb-1 ${this.warehousestatement.pay_period==6 ? 'required' : ''}`"
              >
                <b-form-input
                    id="memo"
                    size="sm"
                    v-model="warehousestatement.memo"
                />
              </b-form-group>
            </b-col>

            <!--超期时效 -->
            <b-col md="4">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="超期时效"
                  label-for="statement_over_max"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="statement_over_max"
                    size="sm"
                    type="number"
                    v-model="warehousestatement.statement_over_max"
                    placeholder="天"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <b-col md="12">
        <div class="inner-card-title">费用规则</div>
      </b-col>
      <div>
        <rule-select :companyId="company_id"></rule-select>
      </div>
      <b-col md="12">
        <b-row>
          <b-col
              cols="12"
              class="mt-50"
          >
            <b-button
                variant="primary"
                class="mr-1"
                @click="save"
            >
              保存
            </b-button>
            <b-button
                variant="outline-secondary"
                @click="cancel"
            >
              取消
            </b-button>
          </b-col>
        </b-row>
      </b-col>


    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import store from "@/store";
import {useToast} from 'vue-toastification/composition'
import axios from '@axios'
import ModalSelect from "@/views/components/modal/ModalSelect";
import {
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  addNumber,
  isEmpty,
  second, isNumber, isEmptys
} from '@core/utils/filter'
import companyStore from "@/views/apps/company/companyStore";
import bankaccountStore from "@/views/apps/bankaccount/bankaccountStore";
import warehousestatementStore from "@/views/apps/warehousestatement/warehousestatementStore";
import RuleSelect from "@/views/apps/warehousestatementrule/WarehouseStatementRuleAddModal/WarehouseStatementRuleList";

export default {
  components: {
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    addNumber,
    isEmpty,
    Number,
    ModalSelect,
    RuleSelect
  },
  data() {
    return {
      warehousestatement: {},
      statement_type: null,
      pay_period: null,
    }
  },
  props: {
    company_id: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const toast = useToast()

    // Register module
    if (!store.hasModule('warehousestatementstore')) store.registerModule('warehousestatementstore', warehousestatementStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('warehousestatementstore')) store.unregisterModule('warehousestatementstore')
    })

    const edit = function () {
      store.dispatch('warehousestatementstore/editNew', {company_id: props.company_id}).then(res => {
        this.warehousestatement = res.data.data
        if (this.warehousestatement.id != 0) {
          this.statement_type = getCode('statement_type', this.warehousestatement.statement_type)
          this.pay_period = getCode('preorder_pay_preiod', this.warehousestatement.pay_period)
          // this.warehousestatement.statement_day = toDate(this.warehousestatement.statement_day)
        }
      })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      let flag = true
      if (isEmpty(this.warehousestatement.statement_type)) {
        toast.error('请选择 结算方式')
        flag = false
      }
      if (this.warehousestatement.statement_type == 2 && isEmpty(this.warehousestatement.statement_day)) {
        toast.error("请填写结算日期,并且范围是1-28")
        return
      }
      if (this.warehousestatement.statement_type == 2 && (this.warehousestatement.statement_day <= 0 || this.warehousestatement.statement_day > 28)) {
        toast.error("请填写结算日期,并且范围是1-28")
        return
      }
      const cRegExp = /^[1-9]\d*$/
      if (this.warehousestatement.statement_type == 2 && !cRegExp.test(this.warehousestatement.statement_day)) {
        toast.error('结算日期必须是正整数!')
        return
      }
      if (isEmpty(this.warehousestatement.pay_period)) {
        toast.error('请选择 付款周期')
        flag = false
      }
      if (isEmptys(this.warehousestatement.statement_over)) {
        toast.error('到账周期 不能为空')
        flag = false
      }
      if (isEmpty(this.warehousestatement.statement_over_max)) {
        toast.error('请填写 超期时效')
        flag = false
      }
      if (this.warehousestatement.pay_period == 6 && isEmpty(this.warehousestatement.memo)) {
        toast.error('请填写 备注')
        flag = false
      }
      if (props.company_id == 0) {
        toast.error('请先填写 主体信息')
        flag = false
      }
      if (!flag) return

      this.warehousestatement.company_id = props.company_id
      // this.warehousestatement.statement_day = second(this.warehousestatement.statement_day)
      axios.post('api/warehousestatement/save', this.warehousestatement).then(res => {
        if (res.data.code == 0) {
          toast.success('数据已保存,可进行其他操作!')
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const changeSelect = function (key, param) {
      if (!isEmpty(param)) {
        this.warehousestatement[key] = param.value
        if (key === "pay_period") {
          if (isNumber(param.value) === 1 || isNumber(param.value) === 5 || isNumber(param.value) === 6) {
            this.warehousestatement.statement_over = 0
          } else if (isNumber(param.value) === 2) {
            this.warehousestatement.statement_over = 30
          } else if (isNumber(param.value) === 3) {
            this.warehousestatement.statement_over = 60
          } else if (isNumber(param.value) === 4) {
            this.warehousestatement.statement_over = 90
          } else if (isNumber(param.value) === 7) {
            this.warehousestatement.statement_over = 7
          } else if (isNumber(param.value) === 8) {
            this.warehousestatement.statement_over = 15
          } else if (isNumber(param.value) === 9) {
            this.warehousestatement.statement_over = 45
          }
          if (param.value == 2 || param.value == 3 || param.value == 4 || param.value == 9) {
            this.statement_type = getCode('statement_type', 2)
            this.warehousestatement.statement_type = 2
          } else {
            this.statement_type = getCode('statement_type', 3)
            this.warehousestatement.statement_type = 3
          }

        }
      } else {
        this.warehousestatement[key] = null
      }
      this.$forceUpdate()
    }

    return {
      edit,
      cancel,
      save,
      changeSelect,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      isEmpty,
      isEmptys,
      Number,
    }
  },
  created() {
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
